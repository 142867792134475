import styled, { css } from 'styled-components';

import { setTypography, spinHexagonIcon } from 'utils/styles/mixins';
import { colors, ColorNames, layout } from 'utils/styles/theme';

const height = 34;

export const Background = styled.div<{ color: ColorNames; isAnchoredRight: boolean }>`
  background: ${({ color }) => colors[color]};
  border-radius: 100rem;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: background 0.2s ease-out, transform 0.2s ease-out;
  z-index: 1;

  ${({ isAnchoredRight }) => `${isAnchoredRight ? 'left' : 'right'}: 0;`}
`;

export const Icon = styled.div<{ color: ColorNames, angle?: number, isSmall: boolean }>`
  color: ${({ color }) => color ? colors[color] : 'currentColor'};
  transform: rotate(${({ angle }) => angle}deg);
  z-index: 1;

  svg {
    height: ${({ isSmall }) => `${isSmall ? 20 : height}rem`};
  }
`;

export const Wrapper = styled.button<{ color: ColorNames; spinIcon: 'icon' | 'path' | 'none' }>`
  ${({ theme: { locale } }) => setTypography('button', locale)}
  display: inline-flex;
  cursor: pointer;
  border-radius: 100rem;
  align-items: center;
  position: relative;
  color: ${({ color }) => colors[color]};
  transition: color 0.2s ease-out;

  &:disabled {
    pointer-events: none;
    filter: brightness(0.5);
  }

  ${({ spinIcon }) => css`
    &:hover, &:active {
      ${Background} {
        transform: scale(1.05);
        filter: brightness(${layout.hoverBrightness});
      }

      ${spinIcon !== 'none' ? css`
        ${Icon} svg {
          ${spinHexagonIcon(spinIcon === 'path')}
        }
      ` : ''}
    }
  `}

  &:active {
    ${Background} {
      transform: scale(1.1);
    }
  }
`;

export const Container = styled.div<{ isBig?: boolean }>`
  display: inline-flex;
  align-items: center;
  padding: ${({ isBig }) => `${isBig ? height : 20}rem ${isBig ? 45 : height}rem`};
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  height: ${height}rem;
  display: flex;
  align-items: center;
  user-select: none;
  padding-top: 0.125em;

  &:not(:first-child) {
    margin-left: 14rem;
  }

  &:not(:last-child) {
    margin-right: 14rem;
  }
`;

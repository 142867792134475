import styled from 'styled-components';
import { motion } from 'framer-motion';

import { colors } from 'utils/styles/theme';
import { hover, setTypography } from 'utils/styles/mixins';

export const Wrapper = styled(motion.div)`
  position: relative;
  width: max-content;
`;

export const List = styled.ul`
  border-radius: 6rem;
  overflow: hidden;
  background-color: ${colors.white};
`;

export const Button = styled.button`
  ${({ theme: { locale } }) => setTypography('button', locale, true)};
  position: relative;
  width: 100%;
  padding: 24rem 86rem;
  text-align: center;
  color: ${colors.dustyGray};
  border-bottom: 1px solid ${colors.mercury};
  transition: color 200ms ease-out, background-color 200ms ease-out, border-bottom 200ms ease-out;
  cursor: pointer;
  user-select: none;
`;

export const ChevronContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24rem;
  opacity: 0;

  svg {
    width: 16rem;

    &:nth-child(1) {
      transform: rotate(180deg);
    }
  }
`;

export const Item = styled.li`
  ${hover(`
    ${Button} {
      color: ${colors.black};
      background-color: ${colors.roti};
      border-bottom: 1px solid ${colors.roti};
    }

    ${ChevronContainer} {
      opacity: 1;
    }
  `)}
`;

export const ItemLabel = styled.span`
  vertical-align: middle;
`;

import { useCallback, useEffect, useState } from 'react';

import PreloaderService from 'u9/services/preloader.service';

const usePreloadDirectory = (directories: string[]) => {
  const [loadProgress, setLoadProgress] = useState<number>(0);

  const onLoadProgress = useCallback((progress: number) => {
    setLoadProgress(progress);
  }, []);

  const onLoadComplete = useCallback(() => {
    setLoadProgress(100);
  }, []);

  useEffect(() => {
    PreloaderService.load(directories, onLoadProgress, onLoadComplete);
  }, [directories, onLoadProgress, onLoadComplete]);

  return loadProgress;
};

export default usePreloadDirectory;

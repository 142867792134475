import styled from 'styled-components';
import { colors, ColorNames } from 'utils/styles/theme';

export const Heading = styled.div`
  max-width: 800rem;
  span {
    white-space: pre-wrap;
    color: ${colors[ColorNames.white]}
  }
`

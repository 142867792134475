import React, { useMemo, useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import gsap from 'gsap';

import LanguageSelector from 'components/LanguageSelector/LanguageSelector';
import { MenuItem } from 'components/ContextualMenu/ContextualMenu';
import Tooltip from 'components/Tooltip/Tooltip';
import { addEventListeners, removeEventListeners } from 'u9/utils/dom';
import gtm from 'u9/utils/gtm';
import { useClubhouseStore, useCopyStore, useGlobalStore } from 'store';
import { BlackBarPositionState, BlackBarTransitionState } from 'store/global.types';
import { ColorNames } from 'utils/styles/theme';

import * as Styled from './HeaderMobileOverlay.styles';

export interface HeaderMobileOverlayRef {
  show: () => gsap.core.Timeline;
  hide: () => gsap.core.Timeline;
}

export interface HeaderMobileOverlayProps {
  toggleAnthemVideoVisible: () => void;
  onPlaylistClick: () => void;
  spotifyTooltipCopy: string;
}

const backingAnimationDuration = 0.5;

const HeaderMobileOverlay = forwardRef<HeaderMobileOverlayRef, HeaderMobileOverlayProps>(({
  toggleAnthemVideoVisible,
  onPlaylistClick,
  spotifyTooltipCopy,
}: HeaderMobileOverlayProps, ref) => {
  const { hasBeenOnboarded, onboardingStep } = useClubhouseStore(
    state => state
  );
  const { user, countryType, setBlackBarTransition } = useGlobalStore(state => state);
  const languageRef = useRef(null);
  const [isLanguageSelectorOpen, setLanguageSelectorOpen] = useState<boolean>(
    false
  );

  const animateInTimeline = useRef<gsap.core.Timeline>(null);
  const animateOutTimeline = useRef<gsap.core.Timeline>(null);
  const backing = useRef();
  const lines = useRef([]);
  const arrows = useRef([]);
  const playlist = useRef();
  const watchCta = useRef();
  const languageSelect = useRef();
  const worldsCta = useRef();
  const footerLinks = useRef();
  const footerBar = useRef();
  const footerBarText = useRef();
  const worldsLeftArrow = useRef();
  const worldsRightArrow = useRef();
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    hide().progress(1);

    return () => {
      if (animateInTimeline.current) animateInTimeline.current.kill();
      if (animateOutTimeline.current) animateOutTimeline.current.kill();
    };
  }, []);

  const show = () => {
    animateInTimeline.current = gsap.timeline()
      .set(arrows.current, {
        opacity: 0,
      })
      .fromTo(
        backing.current,
        {
          scaleY: 0,
        },
        {
          scaleY: 1,
          duration: backingAnimationDuration,
          ease: 'Power3.easeIn',
        }
      )
      .fromTo(
        [playlist.current, watchCta.current],
        {
          y: '350rem',
        },
        {
          y: 0,
          stagger: 0.15,
          duration: 0.5,
          ease: 'Power4.easeOut',
        },
        backingAnimationDuration
      )
      .fromTo(
        arrows.current,
        {
          x: -25,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          duration: 0.2,
          stagger: 0.15,
          ease: 'power2.out',
        },
        '<0.1'
      )
      .fromTo(
        lines.current,
        {
          opacity: 0,
        },
        {
          opacity: 0.2,
          stagger: 0.2,
          duration: 0.2,
        },
        '<'
      )
      .fromTo(
        [languageSelect.current, worldsCta.current],
        {
          opacity: 0,
        },
        {
          opacity: 1,
          stagger: 0.2,
          duration: 0.5,
          ease: 'power2.out',
        },
        backingAnimationDuration + 0.1
      )
      .fromTo(
        worldsLeftArrow.current,
        { x: -5, opacity: 0 },
        { x: 0, opacity: 1, duration: 0.3, ease: 'power2.in' },
        backingAnimationDuration + 0.2
      )
      .fromTo(
        worldsRightArrow.current,
        { x: 5, opacity: 0 },
        { x: 0, opacity: 1, duration: 0.3, ease: 'power2.in' },
        '<'
      )
      .fromTo(
        [footerLinks.current, footerBarText.current],
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.5,
        },
        backingAnimationDuration + 0.4
      )
      .fromTo(
        footerBar.current,
        {
          scaleY: 0,
        },
        {
          scaleY: 1,
          duration: 0.3,
          ease: 'power2.out',
        },
        backingAnimationDuration
      )
      .call(() => {
        setShowTooltip(true);
      }, null, backingAnimationDuration + 0.4);

    return animateInTimeline.current;
  };

  const hide = () => {
    animateOutTimeline.current = gsap.timeline()
      .call(() => {
        setShowTooltip(false);
      })
      .fromTo(
        [footerLinks.current, footerBarText.current],
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 0.5,
        }
      )
      .fromTo(
        [worldsCta.current, languageSelect.current],
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 0.5,
          stagger: 0.2,
          ease: 'power2.out',
        },
        '<'
      )
      .fromTo(
        footerBar.current,
        {
          scaleY: 1,
        },
        {
          scaleY: 0,
          duration: 0.2,
          ease: 'power2.out',
        },
        '-=0.3'
      )
      .fromTo(
        [watchCta.current, playlist.current],
        {
          y: 0,
        },
        {
          y: '350rem',
          stagger: 0.2,
          duration: 0.5,
          ease: 'Power4.easeIn',
        },
        '<-0.3'
      )
      .fromTo(
        [...arrows.current].reverse(),
        {
          x: 0,
          opacity: 1,
        },
        {
          x: -25,
          opacity: 0,
          duration: 0.2,
          stagger: 0.2,
          ease: 'Power2.easeIn',
        },
        '<'
      )
      .fromTo(
        [...lines.current].reverse(),
        {
          opacity: 0.2,
        },
        {
          opacity: 0,
          stagger: 0.2,
          duration: 0.2,
          ease: 'power2.out',
        },
        '>-0.1'
      )
      .fromTo(
        backing.current,
        {
          scaleY: 1,
        },
        {
          scaleY: 0,
          duration: 0.5,
          ease: 'Power3.easeInOut',
        },
        '>-0.1'
      );

    return animateOutTimeline.current;
  };

  useImperativeHandle(
    ref,
    () => ({
      show,
      hide
    })
  );

  useEffect(() => {
    addEventListeners(window, 'click touchend', handleOutsideClick);

    return () => {
      removeEventListeners(window, 'click touchend', handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event: MouseEvent | TouchEvent) => {
    if (languageRef.current && !languageRef.current.contains(event.target)) {
      setLanguageSelectorOpen(false);
    }
  };
  const handleClick = () => {
    setLanguageSelectorOpen(!isLanguageSelectorOpen);
  };

  const handleLegalLinks = (index: number) => {
    let event = '';
    switch (index) {
      case 0:
        event = 'T&Cs';
        break;
      case 1:
        event = 'Privacy Center'
        break;
      case 2:
        event = 'Privacy Policy'
        break;
      default: event = 'Cookies';
    }
    gtm.trackEvent('Global - Footer', 'Click - ' + event, event);
  };

  const copy = useCopyStore(state => state.copy);

  const legalLinks: MenuItem[] = useMemo(() => {
    return copy.experience?.footer?.legal_menu?.map(item => {
      return {
        label: item.label,
        value: item.link,
        isLink: true,
      };
    });
  }, [copy.experience?.footer?.legal_menu]);

  const isTooltipVisible = useMemo(() => showTooltip && onboardingStep === 1, [showTooltip, onboardingStep]);

  if (!copy.experience) return null;
  const {
    experience: { footer: footerCopy, header: headerCopy },
  } = copy;


  if (!footerCopy) return null;

  const worldsColor = ColorNames.roti;
  const handleVideoCta = () => {
    gtm.trackEvent('Global - HeaderMobileOverlay', 'Click - Play', 'Play Anthem');
    setBlackBarTransition(
      BlackBarTransitionState.In,
      BlackBarPositionState.Center,
      BlackBarPositionState.Cover,
      () => {
        toggleAnthemVideoVisible();
      }
    );
  }
  // Allow the user to open the playlists overlay once they've reached the necessary onboarding step
  const shouldShowPlaylists = !!user || hasBeenOnboarded || !!onboardingStep;
  const spotifyLabel = shouldShowPlaylists
    ? headerCopy.spotifyCta_onboarded
    : headerCopy.spotifyCta_notOnboarded;

  return (
    <Styled.Wrapper ref={backing}>
      <Styled.Overlay isVisible={isLanguageSelectorOpen} />

      <Styled.Content>
        <Styled.SectionWrapper>
          <Styled.PlaylistWrapper onClick={onPlaylistClick}>
            <Styled.Label ref={playlist}>{spotifyLabel}</Styled.Label>
            <Styled.ArrowWrapper ref={e => (arrows.current[0] = e)}>
              <Styled.BackArrow color={ColorNames.blueRibbon} />
            </Styled.ArrowWrapper>
          </Styled.PlaylistWrapper>

          <Styled.TooltipWrapper>
            <Tooltip
              isVisible={isTooltipVisible}
              label={spotifyTooltipCopy}
              xPosition={'center'}
              yPosition={'top'}
              isOnboarding
            />
          </Styled.TooltipWrapper>
        </Styled.SectionWrapper>

        <Styled.DivideLine ref={e => (lines.current[0] = e)} />

        <Styled.SectionWrapper>
          <Styled.VideoCta onClick={handleVideoCta}>
            <Styled.Label ref={watchCta}>
              {footerCopy.videoCta.mobile}
            </Styled.Label>
            <Styled.ArrowWrapper ref={e => (arrows.current[1] = e)}>
              <Styled.BackArrow color={ColorNames.blueRibbon} />
            </Styled.ArrowWrapper>
          </Styled.VideoCta>
        </Styled.SectionWrapper>

        <Styled.DivideLine ref={e => (lines.current[1] = e)} />

        <Styled.SectionWrapper ref={languageSelect}>
          <Styled.SelectorWrapper ref={languageRef} onClick={handleClick}>
            <LanguageSelector longLabel={true} />
          </Styled.SelectorWrapper>

          <Styled.WorldsCta ref={worldsCta}>
            <Styled.BackArrowWrapper ref={worldsLeftArrow}>
              <Styled.BackArrow color={worldsColor} />
            </Styled.BackArrowWrapper>

            <a href={footerCopy.credits1_link} target="_blank" rel="noreferrer">
              {footerCopy.credits1}
            </a>
            <Styled.BackArrowWrapper ref={worldsRightArrow}>
              <Styled.BackArrowRight color={worldsColor} />
            </Styled.BackArrowWrapper>
          </Styled.WorldsCta>
        </Styled.SectionWrapper>
      </Styled.Content>

      <Styled.Footer>
        <Styled.LinkWrapper ref={footerLinks}>
          {legalLinks.map((item, idx) => (
            <Styled.LinkList
              key={idx}
              {...(idx !== legalLinks.length - 1 || countryType !== 1 ? {
                href: item.value,
                target: '_blank',
                onClick: () => handleLegalLinks(idx)
              } : {})}
              {...(countryType === 1 && idx === legalLinks.length - 1 ? { id: 'ot-sdk-btn', className: 'ot-sdk-show-settings' } : {})}
            >
              {item.label}
            </Styled.LinkList>
          ))}
        </Styled.LinkWrapper>

        <Styled.FooterSpotify ref={footerBar}>
          <Styled.FooterText ref={footerBarText}>
            {footerCopy.credits2}
          </Styled.FooterText>
        </Styled.FooterSpotify>
      </Styled.Footer>
    </Styled.Wrapper>
  );
});

export default React.memo(HeaderMobileOverlay);

import styled from 'styled-components';
import { motion } from 'framer-motion';

import { setTypography } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';
import { centeredFullscreen } from 'utils/styles/mixins';
import Background404 from 'assets/images/nonFunctionals/404_background.jpg'

export const Wrapper = styled(motion.div)`
  ${centeredFullscreen()}
  background: ${colors.blueRibbon};
  display: flex;
  flex-direction: colume;
  z-index: ${layout.zIndex.nonFunctionals};
  &::before{
      content: "";
      position: absolute;
      opacity: 0.15;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      background-image: url(${Background404});
      z-index: -1;
  }
`;

export const LeadBody = styled.div`
  ${setTypography('leadBody')};
  color: ${colors.white};
  max-width: 1000rem;
  margin-bottom: 70rem;
`
export const BillboardWrapper = styled.div`
  margin-top: -200rem;
  margin-bottom: 100rem;
  width: 100%;
`

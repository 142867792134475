import React, { useMemo } from 'react';
import { useRouter } from 'next/router';

import { MenuItem } from 'components/ContextualMenu/ContextualMenu';
import ContextualMenuSelector from 'components/ContextualMenuSelector/ContextualMenuSelector';
import { useCopyStore } from 'store';
import { ROUTES } from 'utils/routes';
import gtm from 'u9/utils/gtm';

interface LanguageSelectorProps {
  longLabel?: boolean
}

const defaultProps = {
  longLabel: false
};

const LanguageSelector = ({
  longLabel
}: LanguageSelectorProps) => {
  const { experience: { footer: { languages: languagesCopy } } } = useCopyStore(state => state.copy);
  const languages: MenuItem[] = useMemo(() => {
    return languagesCopy.map((lang) => ({
      label: lang.long,
      shortLabel: lang.short,
      value: lang.code
    }));
  }, [languagesCopy]);

  // Add fallback for Storybook testing
  const router = useRouter() || { locale: languages[0].value, push: () => null };

  const handleLanguageSelect = (lang: MenuItem['value']) => {
    router.push(ROUTES.HOME, ROUTES.HOME, { locale: lang });
    gtm.trackEvent('Global - Footer', 'Click - Language', lang);
  };

  const selectedLanguage = languages.filter(lang => lang.value === router.locale)[0];

  const selectorLabel = longLabel ? selectedLanguage.label : selectedLanguage.shortLabel;

  return (
    <ContextualMenuSelector
      items={languages}
      label={selectorLabel}
      onItemSelect={handleLanguageSelect}
    />
  );
};

LanguageSelector.defaultProps = defaultProps;

export default LanguageSelector;

import React from 'react';

import { copyBeforeTag, copyAfterTag } from 'utils/split-text';
import { SharedCopy } from 'store/copy.types';

import * as Styled from './JavaScriptDisabled.styles';
import * as NonFunctionalStyles from './../NonFunctionals.styles';

interface JavascriptDisabled {
  sharedCopy: SharedCopy;
}

const JavascriptDisabled = ({ sharedCopy }: JavascriptDisabled) => {
  if (!sharedCopy.global || !sharedCopy.experience) return null;
  const { global: { errors: { javascriptDisabled: copy } } } = sharedCopy;

  return (
    <noscript>
      <Styled.Wrapper>
        <Styled.HeadingWrapper>
          <Styled.Heading dangerouslySetInnerHTML={{ __html: copy.title}}></Styled.Heading>
        </Styled.HeadingWrapper>
        <Styled.BodyWrapper>
          <Styled.TextContainer>
            <NonFunctionalStyles.LeadBody>
              {copyBeforeTag(copy.body1)}
            </NonFunctionalStyles.LeadBody>
            <Styled.Body>
              {copyAfterTag(copy.body1)}
            </Styled.Body>
          </Styled.TextContainer>
          <Styled.DivideBar />
          <Styled.TextContainer>
            <NonFunctionalStyles.LeadBody>
              {copyBeforeTag(copy.body2)}
            </NonFunctionalStyles.LeadBody>
            <Styled.Body>
              {copyAfterTag(copy.body2)}
            </Styled.Body>
          </Styled.TextContainer>
        </Styled.BodyWrapper>
      </Styled.Wrapper>
    </noscript>
  )
};

export default JavascriptDisabled;

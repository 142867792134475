import styled from 'styled-components';
import { colors } from 'utils/styles/theme';
import { setTypography } from 'utils/styles/mixins';

export const Title = styled.div`
  ${setTypography('heading5')};
  color: ${colors.roti};
  span {
    color: ${colors.white};
  }
`
export const Roti = styled.span`
  color: ${colors.roti};
`
export const White = styled.span`
  color: ${colors.white};
`
export const Body = styled.div`
  max-width: 600rem;
  margin-top: 30rem;
`

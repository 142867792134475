import styled from 'styled-components';
import { motion } from 'framer-motion';

import { ReactComponent as WorldsLogo } from 'svgs/worlds.svg'
import { ReactComponent as SpotifyLogo } from 'svgs/spotify.svg'
import { colors, ColorNames, layout } from 'utils/styles/theme';
import { setVh, setTypography } from 'utils/styles/mixins';

export const Wrapper = styled.div<{ position?: string }>`
  position: ${(props) => props.position ? props.position : 'fixed'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${layout.headerFooterPadding.desktop}rem;
  height: ${setVh(layout.headerFooterHeight.desktop)};
  z-index: ${layout.zIndex.header};
  width: 100%;
  top: 0;
  pointer-events: none;
  opacity: 0;
  user-select: none;
  > * {
    pointer-events: auto;
  }
`;

export const Left = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 40%;
`;

export const Right = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 50%;
`;

export const StyledWorldsLogo = styled(WorldsLogo) <{ color?: ColorNames }>`
  color: ${({ color }) => color ? colors[color] : 'currentColor'};
  margin-left: 100rem;
  height: 100%;
`

export const Spotify = styled(SpotifyLogo) <{ color?: ColorNames }>`
  color: ${({ color }) => color ? colors[color] : 'currentColor'};
  height: 100%;
`

export const LandingPageHeader = styled.p`
  ${({ theme: { locale } }) => setTypography('subtitle1', locale)}
`;

export const ButtonWrapper = styled(motion.div)`
  position: relative;
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 110%);
  display: flex;
  width: 600rem;
  justify-content: flex-end;
`;

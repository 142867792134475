import styled from 'styled-components';
import { colors, layout } from 'utils/styles/theme';
import { mediaTablet } from 'utils/styles/responsive';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${colors.roti};
  padding: ${layout.headerFooterPadding.mobile * 0.4}rem ${layout.headerFooterPadding.mobile / 2}rem;
  z-index: ${layout.zIndex.nonFunctionals + 2};
  ${mediaTablet(`
    padding: ${layout.headerFooterPadding.desktop * 0.4}rem ${layout.headerFooterPadding.desktop}rem;
  `)}
  svg{
    height: 1em;
  }
`;

export const Description = styled.div`
  color: ${colors.white};
  cursor: pointer;
`
export const Left = styled.div`
  display: flex;
  align-items: center;
  svg{
    height: 50rem;
    margin-right: 60rem;
  }
  ${mediaTablet(`
    svg{
      margin-right: 40rem;
    }
  `)}
`;

export const Arrow = styled.div`
  cursor: pointer;
`;

import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors, layout, ColorNames } from 'utils/styles/theme';
import { motion } from 'framer-motion';

import { TooltipProps } from './Tooltip';

export const Wrapper = styled(motion.div) <{ x: TooltipProps['xPosition']; y: TooltipProps['yPosition'] }>`
  ${({ theme: { locale } }) => setTypography('leadBody2', locale)};
  position: relative;
  padding: 40rem 54rem;
  background: ${colors.white};
  color: ${colors.black};
  border-radius: 6rem;
  text-align: center;
  width: fit-content;
  margin-top: ${layout.tooltipSize}rem;
  max-width: 600rem;
  z-index: ${layout.zIndex.header};

  &::before{
    content: '';
    position: absolute;
    ${({ y }) => y}: 3rem;
    left: ${({ x }) => x === 'center' ? 50 : x === 'left' ? 17.5 : 82.5}%;
    display: block;
    border-right: ${layout.tooltipSize}rem solid transparent;
    border-bottom: ${layout.tooltipSize}rem solid ${colors.white};
    border-left: ${layout.tooltipSize}rem solid transparent;
    border-top: ${layout.tooltipSize}rem solid transparent;
    border-radius: 4rem;
    transform: translate(-50%, ${({ y }) => (y === 'bottom' ? 1 : -1) * 100}%) ${({ y }) => y === 'bottom' ? 'rotate(180deg)' : ''};
  }
`;

export const Step = styled.div`
  ${({ theme: { locale } }) => setTypography('caption', locale)};
  display: block;
  margin-bottom: ${layout.getStartedMargin.bottom}rem;

  span {
    color: ${colors[ColorNames.roti]};
  }
`;

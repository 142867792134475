import React, { useEffect, useState, useRef } from 'react';

import ContextualMenu, { MenuItem } from 'components/ContextualMenu/ContextualMenu';
import { SOUNDS_DATA } from 'services/audioManager/audioManager.data';
import AudioManager from 'services/audioManager/audioManager.service';
import { ReactComponent as ChevronSvg } from 'svgs/selector-chevron.svg';
import { addEventListeners, removeEventListeners } from 'u9/utils/dom';
import { isDesktop } from 'u9/utils/platform';

import * as Styled from './ContextualMenuSelector.styles';

interface SelectorProps {
  items?: MenuItem[];
  label?: string;
  onItemSelect?: (val: MenuItem['value']) => void;
}

const defaultProps: SelectorProps = {
  items: [],
  label: '',
  onItemSelect: () => null,
};

const ContextualMenuSelector = ({
  items,
  label,
  onItemSelect,
}: SelectorProps) => {
  const [active, setActive] = useState<boolean>(false);
  const ref = useRef(null);

  const handleSelectorEnter = () => {
    setActive(true);
  };

  const handleSelectorLeave = () => {
    setActive(false);
  };

  const handleSelectorToggle = () => {
    setActive(!active);
  };

  const handleItemSelect = (val: MenuItem['value']) => {
    AudioManager.play(SOUNDS_DATA.buttonClick);
    onItemSelect(val);
    setActive(false);
  };

  const handleOutsideClick = (event: MouseEvent | TouchEvent) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setActive(false);
    }
  };

  const getMouseEventBindings = () => {
    return {
      onClick: handleSelectorToggle,
      ...(isDesktop() ? {
        onMouseEnter: handleSelectorEnter,
        onMouseLeave: handleSelectorLeave,
      } : {})
    };
  };

  useEffect(() => {
    addEventListeners(window, 'click touchend', handleOutsideClick);

    return () => {
      removeEventListeners(window, 'click touchend', handleOutsideClick);
    };
  }, []);

  return (
    <Styled.Wrapper {...getMouseEventBindings()} ref={ref}>
      <Styled.MenuWrapper>
        <ContextualMenu
          onItemClick={handleItemSelect}
          isActive={active}
          items={items}
        />
      </Styled.MenuWrapper>
      <Styled.Selector>
        <Styled.Label>{label}</Styled.Label>
        <Styled.SvgWrapper active={active}>
          <ChevronSvg />
        </Styled.SvgWrapper>
      </Styled.Selector>
    </Styled.Wrapper>
  );
};

ContextualMenuSelector.defaultProps = defaultProps;

export default ContextualMenuSelector;

import React, { useEffect } from 'react';

import { useCopyStore } from 'store';
import Ribbon from 'components/Ribbon/Ribbon';
import { overlayMotionProps } from 'utils/styles/animations';
import { ColorNames } from 'utils/styles/theme';
import { isTablet } from 'u9/utils/platform';

import * as Styled from './RotateDevice.styles';
import * as NonFunctionalStyles from './../NonFunctionals.styles'

const RotateDevice = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const copies = useCopyStore(state => state.copy);

  if (!copies.global || !copies.experience) return null;
  const { global: { errors: { rotateDevice: copy } } } = copies;
  const { experience: { landingPage: { ribbon: ribbonCopy } } } = copies;

  const bodyCopy = isTablet() ? copy.body.tablet : copy.body.mobile;
  return (
    <NonFunctionalStyles.Wrapper {...overlayMotionProps}>
      <Styled.Title dangerouslySetInnerHTML={{ __html: copy.title }}>
      </Styled.Title>
      <Styled.Body>
        {bodyCopy}
      </Styled.Body>
      <NonFunctionalStyles.Footer>
        <Ribbon
          copy={ribbonCopy}
          textColor={ColorNames.roti}
          backgroundColor={ColorNames.black}
          alignment={'horizontal'}
          iconColor={ColorNames.white}
        />
      </NonFunctionalStyles.Footer>
    </NonFunctionalStyles.Wrapper>
  );
};

export default RotateDevice;

import { useRef, useEffect } from 'react';
import gsap from 'gsap';

const useMotion = ({
  wrapper
}) => {
  const landingMotion = useRef(null);

  useEffect(() => {
    if (wrapper) {
      landingMotion.current = gsap.timeline({ paused: false, delay: 5 });
      landingMotion.current
        .fromTo(
          wrapper,
          { opacity: 0 },
          { opacity: 1, duration: 0.1, ease: 'none' }
        )
        .fromTo(
          wrapper,
          { padding: '0 220rem' },
          { padding: '0 100rem', duration: 1, ease: 'power4.inOut' },
          0
        );
    }

    return () => {
      if (landingMotion.current) {
        landingMotion.current.kill();
        landingMotion.current = null;
      }
    };
  }, [wrapper]);

  return {
    landingMotion: landingMotion.current
  };
};

export default useMotion;

import React, { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';

import { SOUNDS_DATA } from 'services/audioManager/audioManager.data';
import AudioManager from 'services/audioManager/audioManager.service';
import { layout } from 'utils/styles/theme';
import { useClubhouseStore, useCopyStore } from 'store';

import * as Styled from './Tooltip.styles';

export const validXPositions = ['left', 'center', 'right'] as const;
export const validYPositions = ['top', 'bottom'] as const;

export interface TooltipProps {
  label: string;
  isVisible: boolean;
  isOnboarding?: boolean;
  xPosition?: typeof validXPositions[number];
  yPosition?: typeof validYPositions[number];
}

const defaultProps: Partial<TooltipProps> = {};

const Tooltip = ({ label, isVisible = false, isOnboarding = false, xPosition = 'center', yPosition = 'top' }: TooltipProps) => {
  const motionProps = {
    initial: { opacity: 0, y: `${(yPosition === 'top' ? -1 : 1) * layout.tooltipSize}rem` },
    animate: { opacity: 1, y: '0rem' },
    exit: { opacity: 0, y: `${(yPosition === 'top' ? -1 : 1) * layout.tooltipSize}rem` },
    transition: { duration: 0.2 },
  };

  const { onboardingStep } = useClubhouseStore((state) => state);
  const {
    experience: {
      clubhouseEntry
    }
  } = useCopyStore(state => state.copy);

  useEffect(() => {
    if (isVisible) AudioManager.play(SOUNDS_DATA.onboardPrompt);
  }, [isVisible]);

  return (
    <AnimatePresence>
      {isVisible && <Styled.Wrapper x={xPosition} y={yPosition} {...motionProps}>
        {/* onboardingStep is +2 because opening the first hotspot doesn't advance the step */}
        {isOnboarding && <Styled.Step>{clubhouseEntry.getStarted} <span>{onboardingStep + 2}/4</span></Styled.Step>}
        {label}
      </Styled.Wrapper>}
    </AnimatePresence>
  );
};

Tooltip.defaultProps = defaultProps;

export default Tooltip;

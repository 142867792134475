import styled from 'styled-components';
import { layout } from 'utils/styles/theme';

export const BodyWrapper = styled.div`
  max-width: 1200rem;
  margin: 70rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  right: 0;
  top: 0;
  padding-top: ${layout.headerFooterPadding.desktop / 2}rem;
  padding-bottom: ${layout.headerFooterPadding.desktop / 2 + layout.ribbon.width.mobile}rem;
  padding-right: ${layout.headerFooterPadding.mobile / 2}rem;
  svg {
    height: 60rem;
    &:first-child {
      transform: rotate(180deg);
    }
  }
`;

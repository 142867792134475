import styled from 'styled-components';

import { colors, ColorNames, layout } from 'utils/styles/theme';
import { setVh } from 'utils/styles/mixins';

export const Wrapper = styled.div`
  position: absolute;
  z-index: ${layout.zIndex.mobileHeader};
  width: 100%;
  top: 0;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
`;

export const HeaderContainer = styled.div<{ isLandscape?: boolean }>`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${layout.headerFooterPadding.mobile}rem;
  height: ${({ isLandscape }) => setVh(isLandscape ? layout.headerFooterHeight.mobileLandscape : layout.headerFooterHeight.mobile)};
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
`;

export const Left = styled.div<{ isLandscape?: boolean }>`
  position: ${({ isLandscape }) => isLandscape ? 'absolute' : 'relative'};
  left: ${({ isLandscape }) => isLandscape ? '50%' : 'unset'};
  transform: ${({ isLandscape }) => isLandscape ? 'translateX(-50%)' : 'unset'};
  display: flex;
  height: 100%;

  > div {
    height: 100%;
    display: flex;
    align-items: center;
    position: ${({ isLandscape }) => isLandscape ? 'relative' : 'absolute'};;
  }
`;

export const Right = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: ${layout.headerCloseIconSize.mobile}rem;
  height: 100%;
  color: ${colors.roti};
  cursor: pointer;

  svg {
    width: 100%;
    height: auto;
  }
`;

export const DivideBar = styled.div`
  margin: 0 50rem;
  height: 55%;
  border-right: 2px solid ${colors.roti};
`;

export const WorldsLogo = styled.div<{ color?: ColorNames }>`
  color: ${({ color }) => color ? colors[color] : 'currentColor'};
  height: 55%;

  svg {
    height: 100%;
  }
`

export const SpotifyLogo = styled.div<{ color?: ColorNames }>`
  color: ${({ color }) => color ? colors[color] : 'currentColor'};
  height: 35%;

  svg {
    height: 100%;
  }
`

export const TooltipWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: auto;
  transform: translateY(90%);
  display: flex;
  padding: 0 ${layout.headerFooterPadding.mobile / 2}rem;
  justify-content: flex-start;
`;

import React from 'react';
import { useRouter } from 'next/router';

import Button from 'components/Button/Button';
import Billboard from 'components/Billboard/Billboard';
import Ribbon from 'components/Ribbon/Ribbon';
import { useCopyStore } from 'store';
import { ROUTES } from 'utils/routes';
import { overlayMotionProps } from 'utils/styles/animations';
import { ColorNames } from 'utils/styles/theme';

import * as Styled from './NotFound.styles';
import * as NonFunctionalStyles from './../NonFunctionals.styles'

const NotFound = () => {
  const router = useRouter() || { push: () => null };

  const handleBackButton = () => {
    router.push(ROUTES.HOME);
  }

  const copies = useCopyStore(state => state.copy);

  if (!copies.global || !copies.experience) return null;
  const { global: { errors: { notFound: copy } } } = copies;
  const { experience: { landingPage: { ribbon: ribbonCopy } } } = copies;

  return (
    <Styled.Wrapper {...overlayMotionProps}>
      <Styled.BillboardWrapper>
        <Billboard
          copy={copy.title}
          textColor={ColorNames.white}
          isScrolling={true}
          mobileRows={1}
        />
      </Styled.BillboardWrapper>
      <Styled.LeadBody>
        {copy.body}
      </Styled.LeadBody>
      <Button
        label={copy.cta}
        color={ColorNames.black}
        onClick={() => {
          handleBackButton();
        }}
        isBig={true}
        icon={'chevron'}
        iconAngle={180}
        isSmallIcon={true}
      />
      <NonFunctionalStyles.Footer>
        <Ribbon
          copy={ribbonCopy}
          textColor={ColorNames.roti}
          backgroundColor={ColorNames.black}
          alignment={'horizontal'}
          iconColor={ColorNames.white}
        />
      </NonFunctionalStyles.Footer>
    </Styled.Wrapper>
  );
};

export default NotFound;

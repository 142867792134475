import React from 'react';

import { useCopyStore } from 'store';
import { copyBeforeTag, copyAfterTag } from 'utils/split-text';
import Ribbon from 'components/Ribbon/Ribbon';
import { overlayMotionProps } from 'utils/styles/animations';
import { ColorNames } from 'utils/styles/theme';

import * as Styled from './WebGLDisabled.styles'
import * as NonFunctionalStyles from './../NonFunctionals.styles'

const WebGLDisabledProps = () => {
  const copies = useCopyStore(state => state.copy);

  if (!copies.global || !copies.experience) return null;
  const { global: { errors: { webGLDisabled: copy } } } = copies;
  const { experience: { landingPage: { ribbon: ribbonCopy } } } = copies;

  return (
    <NonFunctionalStyles.Wrapper {...overlayMotionProps}>
      <NonFunctionalStyles.Title>
        {copy.title}
      </NonFunctionalStyles.Title>
      <Styled.BodyWrapper>
        <NonFunctionalStyles.LeadBody>
          {copyBeforeTag(copy.body)}
        </NonFunctionalStyles.LeadBody>
        <NonFunctionalStyles.Body>
          {copyAfterTag(copy.body)}
        </NonFunctionalStyles.Body>
      </Styled.BodyWrapper>
      <NonFunctionalStyles.Footer>
        <Ribbon
          copy={ribbonCopy}
          textColor={ColorNames.roti}
          backgroundColor={ColorNames.black}
          alignment={'horizontal'}
          iconColor={ColorNames.white}
        />
      </NonFunctionalStyles.Footer>
    </NonFunctionalStyles.Wrapper>
  );
};

export default WebGLDisabledProps;

import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useCopyStore } from 'store';

import { getBannerMotionProps } from 'utils/styles/animations';
import { ReactComponent as SvgWarning } from 'svgs/warning.svg';
import { ReactComponent as SvgArrow } from 'svgs/arrow.svg';

import * as Styled from './UnsupportedBrowserBanner.styles';

export interface UnsupportedBannerProps {
  onTextClick: () => void;
}

const defaultProps: Partial<UnsupportedBannerProps> = {};

const UnsupportedBanner = ({ onTextClick }: UnsupportedBannerProps) => {
  const [showBanner, setShowBanner] = useState(true);

  const handleArrowClick = () => {
    setShowBanner(false);
  }

  const { global } = useCopyStore((state) => state.copy);
  if (!global) return null;
  const { errors: { unsupportedBrowser: { body: { banner: copy } } } } = global;

  return (
    <AnimatePresence>
      {showBanner && <Styled.Wrapper {...getBannerMotionProps('top')}>
        <Styled.Left>
          <SvgWarning />
          <Styled.Description onClick={onTextClick}>{copy}</Styled.Description>
        </Styled.Left>
        <Styled.Arrow onClick={handleArrowClick}>
          <SvgArrow />
        </Styled.Arrow>
      </Styled.Wrapper>}
    </AnimatePresence>
  );
};

UnsupportedBanner.defaultProps = defaultProps;

export default UnsupportedBanner;

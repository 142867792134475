import React, {
  forwardRef,
  SyntheticEvent,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import gsap from 'gsap';

import { useSvgIcon } from 'u9/hooks/useSvgIcon';
import { SOUNDS_DATA } from 'services/audioManager/audioManager.data';
import AudioManager from 'services/audioManager/audioManager.service';
import { ColorNames } from 'utils/styles/theme';

import * as Styled from './Button.styles';

export const validIcons = [
  'button-hexagon',
  'hexagon-icon',
  'spotify-circle-green',
  'spotify-circle-white-black',
  'spotify-circle-white-green',
  'spotify-circle-transparent',
  'checkmark',
  'chevron',
  'download',
  'share',
  'save',
  'update',
] as const;

export interface ButtonRef {
  show: () => gsap.core.Timeline;
}

export interface ButtonProps {
  label: string;
  onClick: (event: SyntheticEvent) => void;
  icon?: typeof validIcons[number];
  color?: ColorNames;
  iconColor?: ColorNames;
  isDisabled?: boolean;
  isBig?: boolean;
  iconAngle?: number;
  isSmallIcon?: boolean;
  isLeftIcon?: boolean;
  showFrom?: 'left' | 'right';
  autoAnimate?: boolean;
}

const Button = forwardRef<ButtonRef, ButtonProps>(
  (
    {
      label,
      color = ColorNames.blueRibbon,
      iconColor,
      icon,
      onClick,
      isDisabled = false,
      isBig = false,
      iconAngle = 0,
      isSmallIcon = false,
      isLeftIcon = false,
      showFrom = 'left',
      autoAnimate = true
    },
    ref
  ) => {
    const { SvgIcon } = useSvgIcon(icon);
    const textColor = [ColorNames.white].includes(color)
      ? ColorNames.black
      : ColorNames.white;

    const timelineRef = useRef<gsap.core.Timeline>(null);
    const wrapperRef = useRef<HTMLButtonElement>(null);
    const backgroundRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const iconRef = useRef<HTMLDivElement>(null);

    const iconDOM = useMemo(
      () =>
        icon ? (
          <Styled.Icon
            ref={iconRef}
            color={iconColor}
            angle={iconAngle}
            isSmall={isSmallIcon}
          >
            <SvgIcon />
          </Styled.Icon>
        ) : // eslint-disable-next-line react-hooks/exhaustive-deps
          null,
      [icon, iconColor, iconAngle, isSmallIcon]
    );

    const playClickSFX = () => {
      const proceed = () => {
        AudioManager.play(SOUNDS_DATA.buttonClick);
      };

      if (AudioManager.isUnlocked) proceed();
      else if (AudioManager.cache[SOUNDS_DATA.buttonClick.id]) {
        AudioManager.cache[SOUNDS_DATA.buttonClick.id].sound.once('unlock', proceed);
      }
    };

    const handleClick = (event: SyntheticEvent) => {
      playClickSFX();
      onClick(event);
    };

    const show = () => {
      if (timelineRef.current) timelineRef.current.kill();

      timelineRef.current = gsap.timeline({
        onComplete: () => {
          timelineRef.current = null;
        },
      });

      timelineRef.current.fromTo(
        wrapperRef.current,
        {
          opacity: 0,
        },
        {
          duration: 0.15,
          opacity: 1,
        }
      );

      timelineRef.current.fromTo(
        backgroundRef.current,
        {
          width: '175%',
        },
        {
          duration: 0.4,
          width: '100%',
          ease: 'Power4.easeIn',
        },
        0.2
      );

      timelineRef.current.fromTo(
        [contentRef.current, iconRef.current],
        {
          x: (index: number) =>
            `${(icon
              ? showFrom === 'left'
                ? -1
                : 1
              : showFrom === 'left'
                ? 1
                : -1) * (index ? 100 : 25)
            }%`,
          opacity: 0,
        },
        {
          duration: 0.4,
          x: '0%',
          opacity: 1,
          ease: 'Power4.easeIn',
          stagger: (showFrom === 'left' ? -1 : 1) * 0.15,
        },
        icon ? 0.1 : 0.2
      );

      if (icon === 'download') {
        const [line, arrow]: SVGPathElement[] = Array.from(
          iconRef.current.querySelectorAll('path')
        );
        const stroke = { value: 50, initial: 50 };

        line.style.strokeDashoffset = `${stroke.initial}`;
        line.style.strokeDasharray = `${stroke.value} ${stroke.initial / 2}`;
        timelineRef.current.to(
          stroke,
          {
            duration: 0.3,
            value: stroke.initial / 2,
            onUpdate: () => {
              line.style.strokeDasharray = `${stroke.value} ${stroke.initial / 2
                }`;
            },
          },
          showFrom === 'left' ? 0.2 : 0.7
        );

        timelineRef.current.fromTo(
          arrow,
          {
            y: '-100%',
          },
          {
            duration: 0.3,
            y: '0%',
          },
          showFrom === 'left' ? 0.2 : 0.7
        );
      }

      return timelineRef.current;
    };

    useImperativeHandle(
      ref,
      (): ButtonRef => ({
        show,
      })
    );

    useEffect(() => {
      autoAnimate && show();

      return () => {
        if (timelineRef.current) timelineRef.current.kill();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Styled.Wrapper
        ref={wrapperRef}
        onClick={handleClick}
        color={textColor}
        disabled={isDisabled}
        spinIcon={
          icon === 'button-hexagon'
            ? 'icon'
            : icon === 'hexagon-icon'
              ? 'path'
              : 'none'
        }
      >
        <Styled.Background
          ref={backgroundRef}
          color={color}
          isAnchoredRight={showFrom === 'right'}
        />
        <Styled.Container isBig={isBig}>
          {icon && isLeftIcon && iconDOM}
          <Styled.Content ref={contentRef}>{label}</Styled.Content>
          {icon && !isLeftIcon && iconDOM}
        </Styled.Container>
      </Styled.Wrapper>
    );
  }
);

Button.displayName = 'Button';

export default React.memo(Button);

import React, { ReactElement } from 'react';

export interface ConditionalLinkWrapperProps {
  condition: boolean;
  href?: string;
  target?: string;
  children: ReactElement;
}

const defaultProps = {
  href: '#',
  target: '_blank'
};

const ConditionalLinkWrapper = ({ condition, href, target, children }: ConditionalLinkWrapperProps) => {
  if (condition) {
    return (
      <a href={href} target={target} rel="noreferrer">
        {children}
      </a>
    );
  }

  return children;
};

ConditionalLinkWrapper.defaultProps = defaultProps;

export default ConditionalLinkWrapper;

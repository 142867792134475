import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import gsap from 'gsap';

import Tooltip from 'components/Tooltip/Tooltip';
import Button, { ButtonRef } from 'components/Button/Button';
import HeaderMobile from 'components/HeaderMobile/HeaderMobile';
import {
  useCardScreenStore,
  useClubhouseStore,
  useCopyStore,
  useGlobalStore,
  usePlaylistsStore,
} from 'store';
import { isMobileLayout } from 'utils/styles/responsive';
import { ColorNames } from 'utils/styles/theme';
import gtm from 'u9/utils/gtm';

import useMotion from './Header.motion';
import * as Styled from './Header.styles';

const motionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 },
};

export interface HeaderProps {
  background?: ColorNames;
  hasMenu?: boolean;
  hasSpotifyCTA?: boolean;
  position?: string;
  toggleAnthemVideoVisible?: () => void;
}

const Header = ({
  background = ColorNames.black,
  hasMenu = true,
  hasSpotifyCTA = true,
  position,
  toggleAnthemVideoVisible,
}: HeaderProps) => {
  const wrapperRef = useRef(null);

  const {
    currentClubhouse,
    hasBeenOnboarded,
    onboardingStep,
  } = useClubhouseStore(state => state);
  const { currentCardScreen, setCurrentCardScreen, setPlaylistCardExiting } = useCardScreenStore(
    state => state
  );
  const { togglePlaylistsOverlayVisible } = usePlaylistsStore(state => state);
  const { user, toggleAuthPromptVisible } = useGlobalStore(state => state);
  const { isReady: isLandingReady } = useGlobalStore(state => state);
  const { isReady: isClubhouseReady } = useClubhouseStore(state => state);

  const timelineRef = useRef<gsap.core.Timeline>(null);
  const buttonRef = useRef<ButtonRef>(null);
  const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const showTooltipTimeout = useRef<number>(null);


  const isBlackBackground = background === ColorNames.black;
  const isBlueBackground = background === ColorNames.blueRibbon;
  const isDarkBackground = isBlackBackground || isBlueBackground;
  const logoColor = isDarkBackground ? ColorNames.white : ColorNames.black;
  const buttonColor =
    background === ColorNames.black
      ? ColorNames.mountainMeadow
      : ColorNames.black;

  const clubhouseName =
    currentClubhouse === 'seoul'
      ? 'LCK'
      : currentClubhouse === 'berlin'
        ? 'LEC'
        : currentClubhouse === 'shanghai'
          ? 'LPL'
          : 'Landing';
  const copy = useCopyStore(state => state.copy);

  useMotion({
    wrapper: wrapperRef.current,
  });

  const animation = useCallback(
    (delay = 0) => {
      if (timelineRef.current) timelineRef.current.kill();

      timelineRef.current = gsap.timeline({
        onComplete: () => {
          timelineRef.current = null;
        },
      });

      if (buttonRef.current) {
        timelineRef.current.add(buttonRef.current.show(), delay);
      }
    },
    []
  );

  useEffect(() => {
    if (isLandingReady) {
      animation();
    } else if (isClubhouseReady) {
      animation(1);
    }
  }, [isLandingReady, isClubhouseReady, animation]);

  useEffect(() => {
    return () => {
      if (timelineRef.current) timelineRef.current.kill();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onboardingStep === 1 && isClubhouseReady) {
      showTooltipTimeout.current = window.setTimeout(() => {
        showTooltipTimeout.current = null;
        setTooltipVisible(true);
      }, 1700);
    }
    else {
      setTooltipVisible(false);
    }
  }, [onboardingStep, isClubhouseReady]);

  useEffect(() => {
    return () => {
      if (showTooltipTimeout.current) window.clearTimeout(showTooltipTimeout.current);
    }
  }, []);

  if (!copy.experience) return null;
  const {
    experience: { header: headerCopy },
  } = copy;

  if (!headerCopy) return null;
  const tooltipCopy = copy?.experience?.clubhouseEntry?.prompts?.playlist ?? '';

  // Allow the user to open the playlists overlay once they've reached the necessary onboarding step
  const shouldShowPlaylists = !!user || hasBeenOnboarded || !!onboardingStep;
  const spotifyLabel = shouldShowPlaylists
    ? headerCopy.spotifyCta_onboarded
    : headerCopy.spotifyCta_notOnboarded;

  const onPlaylistClick = () => {
    if (shouldShowPlaylists) {
      gtm.trackEvent(
        `${clubhouseName} Header`,
        'Click - Your Playlist',
        `${clubhouseName} - Your Playlist`
      );
      if (currentCardScreen === 'playlist') {
        setPlaylistCardExiting(true);
      } else {
        togglePlaylistsOverlayVisible();
      }
    } else toggleAuthPromptVisible();

    if (currentCardScreen === 'welcome') setCurrentCardScreen('');
  };

  return isMobileLayout() ? (
    <HeaderMobile
      background={background}
      hasClubhouse={!!currentClubhouse}
      hasMenu={hasMenu}
      toggleAnthemVideoVisible={toggleAnthemVideoVisible}
      onPlaylistClick={onPlaylistClick}
      spotifyTooltipCopy={tooltipCopy}
    />
  ) : (
    <Styled.Wrapper position={position} ref={wrapperRef}>
      <Styled.Left>
        <Styled.Spotify color={logoColor} />
      </Styled.Left>
      <Styled.Right>
        <AnimatePresence>
          {hasSpotifyCTA && isLandingReady && (
            <Styled.ButtonWrapper {...motionProps}>
              <Button
                label={spotifyLabel}
                color={buttonColor}
                isBig={true}
                onClick={onPlaylistClick}
                showFrom="left"
                ref={buttonRef}
              />
              <Styled.TooltipWrapper>
                <Tooltip
                  isVisible={isTooltipVisible}
                  label={tooltipCopy}
                  xPosition={'center'}
                  yPosition={'top'}
                  isOnboarding
                />
              </Styled.TooltipWrapper>
            </Styled.ButtonWrapper>
          )}
        </AnimatePresence>

        <Styled.StyledWorldsLogo color={logoColor} />
      </Styled.Right>
    </Styled.Wrapper>
  );
};

export default React.memo(Header);

import React from 'react';
import { AnimatePresence } from 'framer-motion';

import ConditionalLinkWrapper from 'components/ConditionalLinkWrapper/ConditionalLinkWrapper';
import { ReactComponent as SvgChevron } from 'svgs/chevron.svg';

import * as Styled from './ContextualMenu.styles';

export interface MenuItem {
  label: string;
  shortLabel?: string;
  value: string;
  isLink?: boolean;
}

export interface MenuProps {
  items?: MenuItem[];
  isActive: boolean;
  onItemClick?: (value: MenuItem['value']) => void;
}

const menuWrapperMotionProps = {
  initial: { opacity: 0, y: '20rem' },
  animate: { opacity: 1, y: '0rem' },
  exit: { opacity: 0, y: '20rem' },
  transition: { duration: 0.2 }
};

const ContextualMenu = ({
  items,
  isActive,
  onItemClick
}: MenuProps) => {
  const itemComponents = items.map((item, index) => {
    return (
      <Styled.Item
        key={`MenuItem-${index}`}
      >
        <ConditionalLinkWrapper
          condition={item.isLink}
          href={item.value}
        >
          <Styled.Button onClick={() => onItemClick(item.value)}>
            <Styled.ChevronContainer>
              <SvgChevron />
              <SvgChevron />
            </Styled.ChevronContainer>
            <Styled.ItemLabel>
              {item.label}
            </Styled.ItemLabel>
          </Styled.Button>
        </ConditionalLinkWrapper>
      </Styled.Item>
    )
  });

  return (
    <AnimatePresence>
      {
        isActive &&
        <Styled.Wrapper
          {...menuWrapperMotionProps}
        >
          <Styled.List>
            {itemComponents}
          </Styled.List>
        </Styled.Wrapper>
      }
    </AnimatePresence>
  );
};

export default React.memo(ContextualMenu);

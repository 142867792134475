import styled from 'styled-components';
import { motion } from 'framer-motion';

import { hover } from 'utils/styles/mixins';
import { ColorNames, colors, layout } from 'utils/styles/theme';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';

export interface WrapperProps {
  color?: ColorNames;
  position?: string;
  zIndex?: number;
  wrapped?: boolean;
}

export const SVGWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: 50% 50%;
`;

export const Wrapper = styled(motion.button) <WrapperProps>`
  position: ${(props) => props.wrapped ? 'relative' : props.position};
  top: ${(props) => props.wrapped ? 0 : layout.headerFooterPadding.mobile}rem;
  right: ${(props) => props.wrapped ? 0 : layout.headerFooterPadding.mobile}rem;
  width: ${layout.headerCloseIconSize.mobile}rem;
  height: ${layout.headerCloseIconSize.mobile}rem;
  display: flex;
  align-items: center;
  z-index: ${(props) => props.zIndex};
  visibility: hidden;
  cursor: pointer;
  svg {

    width: 100%;
    color: ${(props) => colors[props.color]};
  }

  ${hover(`
    svg {
      filter: brightness(${layout.hoverBrightness});
    }
  `)}

  ${({ wrapped }) => mediaTablet(`
    top: ${wrapped ? 0 : layout.headerFooterPadding.tablet - 40}rem;
    right: ${wrapped ? 0 : layout.headerFooterPadding.tablet}rem;
    width: ${layout.headerCloseIconSize.tablet}rem;
    height: ${layout.headerCloseIconSize.tablet}rem;
  `)}

  ${({ wrapped }) => mediaDesktop(`
    top: ${wrapped ? 0 : layout.headerFooterPadding.desktop - 40}rem;
    right: ${wrapped ? 0 : layout.headerFooterPadding.desktop}rem;
    width: ${layout.headerCloseIconSize.desktop}rem;
    height: ${layout.headerCloseIconSize.desktop}rem;
  `)}
`;

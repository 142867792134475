import styled, { css, keyframes } from 'styled-components';
import { colors, layout } from 'utils/styles/theme';
import { setTypography } from 'utils/styles/mixins';
import { centeredFullscreen } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';

const tweenOpacity = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const fadeInWithDelay = css`
  opacity: 0;
  animation: ${tweenOpacity} 0.2s 1s ease-out;
  animation-fill-mode: forwards;
`;

/* Don't show it immediately or it'll flash briefly when the page loads  */
export const Wrapper = styled.div`
  ${centeredFullscreen()};
  ${fadeInWithDelay};
  background: ${colors.blueRibbon};
  display: flex;
  flex-diraction: colume;
  z-index: ${layout.zIndex.nonFunctionals};
`;

export const HeadingWrapper = styled.div`
  ${({ theme: { locale } }) => setTypography('heading5', locale)};
  ${({ theme: { locale } }) => mediaTablet(`
    ${setTypography('heading4', locale)};
  `)};
  color: ${colors.roti}
`

export const Heading = styled.div`
  span {
    color: ${colors.white}
  }
`
export const BodyWrapper = styled.div`
  display:flex;
  max-width: 2000rem;
  margin: 70rem 0;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  ${mediaTablet(`
    flex-direction: row;
  `)}
`

export const TextContainer = styled.div`
  margin:0 140rem;
  display:flex;
  flex-direction: column;
  align-items: center;
`
export const Body = styled.div`
${({ theme: { locale } }) => setTypography('body', locale)};
  color: ${colors.white};
  margin-top: 20rem;
  ${mediaTablet(`
    margin-top: 30rem;
  `)}
`

export const DivideBar = styled.div`
  width: 600rem;
  border-bottom: 1px solid ${colors.white};
  opacity: 0.25;
  margin: 60rem 0;

  ${mediaTablet(`
    width: unset;
    height: 180rem;
    border-bottom: unset;
    margin: unset;
    border-right: 1px solid ${colors.white};
    opacity: 0.25;
  `)}
`;

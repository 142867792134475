import styled from 'styled-components';
import { motion } from 'framer-motion';

import { colors, layout } from 'utils/styles/theme';
import { setTypography, centeredFullscreen } from 'utils/styles/mixins';

export const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: ${layout.zIndex.nonFunctionals + 1};
`;

export const Wrapper = styled(motion.div)`
  ${centeredFullscreen()};
  flex-direction: column;
  background: ${colors.blueRibbon};
  z-index: ${layout.zIndex.nonFunctionals};
  color: ${colors.white};
`;

export const BannerWrapper = styled.div``;

export const Title = styled.div`
  ${setTypography('heading4')};
  color: ${colors.roti};
`;

export const BodyWrapper = styled.div`
  max-width: 1200rem;
  margin-top: 70rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 60rem;
`;

export const LeadBody = styled.div`
  ${setTypography('leadBody')}
  color: ${colors.white}
  max-width: 1000rem;
`

export const Body = styled.div`
  margin-top: 30rem;
  ${setTypography('body')};
  color: ${colors.white};
  white-space: pre-wrap;
  width: 80%;
`
export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
`;

import React, { useState, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Tooltip from 'components/Tooltip/Tooltip';
import gsap from 'gsap';

import ButtonClose from 'components/ButtonClose/ButtonClose';
import { SOUNDS_DATA } from 'services/audioManager/audioManager.data';
import AudioManager from 'services/audioManager/audioManager.service';
import { useClubhouseStore, useGlobalStore } from 'store';
import { ReactComponent as SvgHamburger } from 'svgs/hamburger.svg';
import { ReactComponent as SvgSpotify } from 'svgs/spotify.svg';
import { ReactComponent as SvgWorlds } from 'svgs/worlds.svg';
import { ColorNames, layout } from 'utils/styles/theme';
import { useWindowSize } from 'u9/hooks';

import HeaderMobileOverlay, { HeaderMobileOverlayRef } from './HeaderMobileOverlay/HeaderMobileOverlay';

import * as Styled from './HeaderMobile.styles';

const duration = 0.25;
const distance = 75;

export interface HeaderMobileProps {
  background?: ColorNames;
  hasClubhouse?: boolean;
  hasMenu?: boolean;
  toggleAnthemVideoVisible: () => void;
  onPlaylistClick: () => void;
  spotifyTooltipCopy: string;
}

const HeaderMobile = ({
  background = ColorNames.black,
  hasClubhouse,
  hasMenu = true,
  toggleAnthemVideoVisible,
  onPlaylistClick,
  spotifyTooltipCopy,
}: HeaderMobileProps) => {
  const [isOverlayOpen, setOverlayOpen] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('');
  const windowSize = useWindowSize();
  const menuToggleButton = useRef();
  const openMenuTimeline = useRef(null);
  const closeMenuTimeline = useRef(null);
  const overlayRef = useRef<HeaderMobileOverlayRef>(null);

  const { isReady } = useGlobalStore(state => state);
  const { onboardingStep } = useClubhouseStore(state => state);

  const logoColor =
    backgroundColor === ColorNames.white ? ColorNames.black : ColorNames.white;

  useEffect(() => {
    setBackgroundColor(isOverlayOpen ? ColorNames.black : background);
  }, [background, isOverlayOpen]);

  useEffect(() => {
    return () => {
      if (openMenuTimeline.current) openMenuTimeline.current.kill();
      if (closeMenuTimeline.current) closeMenuTimeline.current.kill();
    };
  }, []);

  const open = () => {
    if (openMenuTimeline.current) openMenuTimeline.current.kill();

    openMenuTimeline.current = gsap.timeline();
    openMenuTimeline.current
      .fromTo(
        menuToggleButton.current,
        {
          x: 0,
        },
        {
          x: distance,
          ease: 'Power4.easeIn',
          duration,
        }
      )
      .fromTo(
        menuToggleButton.current,
        {
          x: distance,
        },
        {
          x: 0,
          immediateRender: false,
          delay: 0.1,
          ease: 'Power4.easeOut',
          duration,
        }
      )
      .add(overlayRef.current.show(), 0);
  };

  const close = () => {
    if (closeMenuTimeline.current) closeMenuTimeline.current.kill();

    closeMenuTimeline.current = gsap.timeline();
    closeMenuTimeline.current
      .fromTo(
        menuToggleButton.current,
        {
          x: 0,
        },
        {
          x: distance,
          ease: 'Power4.easeIn',
          duration,
        }
      )
      .call(() => {
        setOverlayOpen(false);
      })
      .fromTo(
        menuToggleButton.current,
        {
          x: distance,
        },
        {
          x: 0,
          immediateRender: false,
          ease: 'Power4.easeOut',
          duration,
        }
      )
      .add(overlayRef.current.hide(), 0);
  };

  const toggleOverlay = () => {
    setOverlayOpen(!isOverlayOpen);
    if (!isOverlayOpen) open();
    else close();

    AudioManager.play(SOUNDS_DATA.buttonClick);
  };

  const onAnthemVideoToggle = () => {
    toggleAnthemVideoVisible();
    toggleOverlay();
  };

  const handlePlaylistClick = () => {
    onPlaylistClick();
    toggleOverlay();
  };

  const leftSideMotionProps = {
    initial: { opacity: 0, x: `-${layout.headerFooterPadding.mobile}rem` },
    animate: { opacity: 1, x: '0rem' },
    exit: { opacity: 0, x: `-${layout.headerFooterPadding.mobile}rem` },
    transition: { duration: 0.2, delay: isOverlayOpen ? 0.2 : 0 },
  };

  const rightSideMotionProps = {
    initial: { opacity: 0, x: `${layout.headerFooterPadding.mobile}rem` },
    animate: { opacity: 1, x: '0rem' },
    exit: { opacity: 0, x: `${layout.headerFooterPadding.mobile}rem` },
    transition: { duration: 0.2 },
  };

  return (
    <Styled.Wrapper>
      <HeaderMobileOverlay
        ref={overlayRef}
        toggleAnthemVideoVisible={onAnthemVideoToggle}
        onPlaylistClick={handlePlaylistClick}
        spotifyTooltipCopy={spotifyTooltipCopy}
      />
      <Styled.HeaderContainer isLandscape={windowSize.isLandscape}>
        <Styled.TooltipWrapper>
          <Tooltip
            isVisible={onboardingStep === 1 && !isOverlayOpen}
            label={spotifyTooltipCopy}
            xPosition={'right'}
            yPosition={'top'}
            isOnboarding
          />
        </Styled.TooltipWrapper>
        <Styled.Left isLandscape={windowSize.isLandscape}>
          <AnimatePresence>
            {isReady && (!hasClubhouse || isOverlayOpen || windowSize.isLandscape) && (
              <motion.div key="default" {...leftSideMotionProps}>
                <Styled.SpotifyLogo color={logoColor}>
                  <SvgSpotify />
                </Styled.SpotifyLogo>
                <Styled.DivideBar />
                <Styled.WorldsLogo color={logoColor}>
                  <SvgWorlds />
                </Styled.WorldsLogo>
              </motion.div>
            )}
          </AnimatePresence>
        </Styled.Left>

        {hasMenu && (
          <Styled.Right ref={menuToggleButton}>
            <AnimatePresence>
              {isReady && (
                <motion.div {...rightSideMotionProps}>
                  {!isOverlayOpen && <SvgHamburger onClick={toggleOverlay} />}
                  {isOverlayOpen && (
                    <ButtonClose onClick={toggleOverlay} wrapped={true} />
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </Styled.Right>
        )}
      </Styled.HeaderContainer>
    </Styled.Wrapper>
  );
};

export default React.memo(HeaderMobile);

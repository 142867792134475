import React, { useEffect } from 'react';

import Ribbon from 'components/Ribbon/Ribbon';
import { ColorNames } from 'utils/styles/theme';
import Button from 'components/Button/Button';
import { useCopyStore } from 'store';
import { copyBeforeTag, copyAfterTag } from 'utils/split-text';
import { overlayMotionProps } from 'utils/styles/animations';
import { isSocialBrowser, isDesktop } from 'u9/utils/platform';

import * as Styled from './UnsupportedBrowser.styles';
import * as NonFunctionalStyles from './../NonFunctionals.styles'

interface UnsupportedBrowserProps {
  setAccept(state: boolean): void;
}

const UnsupportedBrowserProps = ({ setAccept }: UnsupportedBrowserProps) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const copies = useCopyStore(state => state.copy);

  if (!copies.global || !copies.experience) return null;
  const { global: { errors: { unsupportedBrowser: copy } } } = copies;
  const { experience: { landingPage: { ribbon: ribbonCopy } } } = copies;

  let bodyCopy: string;
  if (isDesktop()) {
    bodyCopy = copy.body.desktop
  }
  else if (isSocialBrowser()) {
    bodyCopy = copy.body.social
  }
  else {
    bodyCopy = copy.body.mobile
  }

  return (
    <NonFunctionalStyles.Wrapper {...overlayMotionProps}>
      <NonFunctionalStyles.Title>
        {copy.title}
      </NonFunctionalStyles.Title>
      <Styled.BodyWrapper>
        <NonFunctionalStyles.LeadBody>
          {copyBeforeTag(bodyCopy)}
        </NonFunctionalStyles.LeadBody>
        <NonFunctionalStyles.Body>
          {copyAfterTag(bodyCopy)}
        </NonFunctionalStyles.Body>
      </Styled.BodyWrapper>
      <Button
        label={copy.cta}
        color={ColorNames.black}
        onClick={() => setAccept(true)}
        isBig={true}
        icon={'chevron'}
        iconAngle={180}
        isSmallIcon={true}
      />
      <NonFunctionalStyles.Footer>
        <Ribbon
          copy={ribbonCopy}
          textColor={ColorNames.roti}
          backgroundColor={ColorNames.black}
          alignment={'horizontal'}
          iconColor={ColorNames.white}
        />
      </NonFunctionalStyles.Footer>
    </NonFunctionalStyles.Wrapper>
  );
};

export default UnsupportedBrowserProps;

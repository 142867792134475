import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import gsap from 'gsap';

import { SOUNDS_DATA } from 'services/audioManager/audioManager.data';
import AudioManager from 'services/audioManager/audioManager.service';
import { ReactComponent as SvgClose } from 'svgs/close.svg';
import { ColorNames } from 'utils/styles/theme';

import * as Styled from './ButtonClose.styles';

export interface ButtonCloseRef {
  show: () => gsap.core.Timeline
}
export interface ButtonCloseProps {
  onClick: () => void;
  color?: ColorNames;
  position?: string;
  zIndex?: number;
  wrapped?: boolean;
  delay?: number;
  autoAnimate?: boolean;
}

const defaultProps = {
  onClick: () => null,
  color: ColorNames.roti,
  position: 'absolute',
  zIndex: 2,
  wrapped: false,
  delay: 0,
  autoAnimate: true
};

const ButtonClose = forwardRef<ButtonCloseRef, ButtonCloseProps>(({ onClick, color, position, zIndex, wrapped, delay, autoAnimate }, ref) => {
  const timelineRef = useRef<gsap.core.Timeline>(null);
  const wrapperRef = useRef<HTMLButtonElement>(null);

  const show = () => {
    if (timelineRef.current) timelineRef.current.kill();
    timelineRef.current = gsap.timeline({
      delay,
      onComplete: () => {
        timelineRef.current = null;
      }
    });

    const rotate1 = { value: -43, initial: -43, final: 0 };
    const rotate2 = { value: 43, initial: 43, final: 0 };
    const [slash1, slash2]: SVGPathElement[] = Array.from(wrapperRef.current.querySelectorAll('svg path'));

    timelineRef.current
      .fromTo(wrapperRef.current,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 0.01,
        }
      )
      .fromTo(rotate1,
        {
          value: rotate1.initial
        },
        {
          duration: 0.5,
          value: rotate1.final,
          ease: 'power3.inOut',
          onStart: () => {
            slash1.style.transformOrigin = '50% 50%';
            slash1.style.transform = `rotate(${rotate1.value}deg)`;
          },
          onReverse: () => {
            slash1.style.transformOrigin = '50% 50%';
            slash1.style.transform = `rotate(${rotate1.value}deg)`;
          },
          onUpdate: () => { slash1.style.transform = `rotate(${rotate1.value}deg)`; }
        },
        0
      )
      .fromTo(rotate2,
        {
          value: rotate2.initial
        },
        {
          duration: 0.5,
          value: rotate1.final,
          ease: 'power3.inOut',
          onStart: () => {
            slash2.style.transformOrigin = '50% 50%';
            slash2.style.transform = `rotate(${rotate2.value}deg)`;
          },
          onUpdate: () => { slash2.style.transform = `rotate(${rotate2.value}deg)`; },
          onReverse: () => {
            slash2.style.transformOrigin = '50% 50%';
            slash2.style.transform = `rotate(${rotate2.value}deg)`;
          },
        },
        0
      );
    return timelineRef.current;
  };

  const handleClick = () => {
    AudioManager.play(SOUNDS_DATA.buttonClick);
    onClick();
  };

  useImperativeHandle(
    ref,
    (): ButtonCloseRef => ({
      show
    })
  );

  useEffect(() => {
    autoAnimate && show();
    return () => {
      if (timelineRef.current) {
        timelineRef.current.kill();
        timelineRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styled.Wrapper
      onTapCancel={handleClick}
      onClick={handleClick}
      color={color}
      position={position}
      zIndex={zIndex}
      wrapped={wrapped}
      ref={wrapperRef}
      whileTap={{
        scale: 0.8,
        transition: { duration: 0.1 }
      }}
    >
      <SvgClose />
    </Styled.Wrapper>
  );
});

ButtonClose.displayName = 'ButtonClose'
ButtonClose.defaultProps = defaultProps;

export default React.memo(ButtonClose);

import React, { useEffect } from 'react';

import { useCopyStore } from 'store';
import { copyBeforeTag, copyAfterTag } from 'utils/split-text';
import Ribbon from 'components/Ribbon/Ribbon';
import { overlayMotionProps } from 'utils/styles/animations';
import { ColorNames } from 'utils/styles/theme';

import * as Styled from './WindowTooSmall.styles';
import * as NonFunctionalStyles from './../NonFunctionals.styles'

const WindowTooSmall = () => {
  const copies = useCopyStore(state => state.copy);
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  if (!copies.global || !copies.experience) return null;
  const { global: { errors: { windowTooSmall: copy } } } = copies;
  const { experience: { landingPage: { ribbon: ribbonCopy } } } = copies;

  return (
    <NonFunctionalStyles.Wrapper {...overlayMotionProps}>
      <NonFunctionalStyles.Title>
        <Styled.Heading dangerouslySetInnerHTML={{ __html: copy.title}}></Styled.Heading>
      </NonFunctionalStyles.Title>
      <NonFunctionalStyles.BodyWrapper>
        <NonFunctionalStyles.LeadBody>{copyBeforeTag(copy.body)}</NonFunctionalStyles.LeadBody>
        <NonFunctionalStyles.Body>{copyAfterTag(copy.body)}</NonFunctionalStyles.Body>
      </NonFunctionalStyles.BodyWrapper>
      <NonFunctionalStyles.Footer>
        <Ribbon
          copy={ribbonCopy}
          textColor={ColorNames.roti}
          backgroundColor={ColorNames.black}
          alignment={'horizontal'}
          iconColor={ColorNames.white}
        />
      </NonFunctionalStyles.Footer>
    </NonFunctionalStyles.Wrapper>
  );
};

export default WindowTooSmall;

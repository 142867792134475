import React, { useEffect, useMemo, useState } from 'react';
import { GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { AnimatePresence } from 'framer-motion';

import { SharedCopy } from 'store/copy.types';
import { useCopyStore, useGlobalStore } from 'store';
import { HeadProps } from 'u9/components/Head/Head';
import useWindowSize from 'u9/hooks/useWindowSize';
import {
  isMobile,
  isSupportedBrowser,
  isDesktop,
  isTablet,
  hasWebGl
} from 'u9/utils/platform';
import { cmsApiClient } from 'utils/cms/api';
import { notFoundPageQuery } from 'utils/cms/gql';
import { ISR_TIMEOUT } from 'utils/config';
import { ROUTES } from 'utils/routes';
import { desktopMinHeight, breakpointDesktop } from 'utils/styles/vars';

import NotFound from './NotFound/NotFound';
import JavaScriptDisabled from './JavaScriptDisabled/JavaScriptDisabled';
import RotateDevice from './RotateDevice/RotateDevice';
import UnsupportedBrowser from './UnsupportedBrowser/UnsupportedBrowser';
import UnsupportedBanner from './UnsupportedBrowserBanner/UnsupportedBrowserBanner';
import SocialBrowserUnsupported from './SocialBrowserUnsupported/SocialBrowserUnsupported';
import WebGLDisabled from './WebGLDisabled/WebGLDisabled';
import WindowTooSmall from './WindowTooSmall/WindowTooSmall';
import Header from 'components/Header/Header';

import * as Styled from './NonFunctionals.styles';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const result = await cmsApiClient.query({
    query: notFoundPageQuery({ locale }),
  });
  const { sharedCopy, notFoundPage: page } = result.data;
  const { head } = page;

  return {
    props: {
      head,
      sharedCopy,
    },
    revalidate: ISR_TIMEOUT,
  };
};

interface NonFunctionalsProps {
  children: React.ReactNode | React.ReactNode[];
  isBypassed?: boolean;
  head: HeadProps;
  sharedCopy: SharedCopy;
}

const NonFunctionals = ({
  children,
  isBypassed = false,
  head,
  sharedCopy,
}: NonFunctionalsProps): JSX.Element => {
  const [isMounted, setMounted] = useState(false);
  const [isRendered, setRendered] = useState(false);
  const [showUnsupportedBrowser, setShowUnsupportedBrowser] = useState(false);
  const [
    isSubStandardBrowserAccepted,
    setSubstandardBrowserAccepted,
  ] = useState(false);
  const [hasNoWebGl, setNoWebGl] = useState(false);
  const [hasBanner, setHasBanner] = useState(false);
  const [showUnsupportedSocial, setUnsupportedSocial] = useState(false);

  // Add fallback for Storybook testing
  const router = useRouter() || { pathname: ROUTES.HOME, asPath: ROUTES.HOME };
  const hasMatchingRoute = useMemo(
    () =>
      Object.values(ROUTES).includes(
        router.pathname as typeof ROUTES[keyof typeof ROUTES]
      ) && router.pathname !== ROUTES.NOT_FOUND,
    [router.pathname]
  );

  const windowSize = useWindowSize(true);
  const { setCopy } = useCopyStore(state => state);
  const { isWindowTooSmall, shouldRotateDevice, setRotateDevice, setWindowTooSmall } = useGlobalStore(state => state);

  const errorComponent = useMemo(() => {
    if (showUnsupportedBrowser && !isSubStandardBrowserAccepted) {
      return (
        <UnsupportedBrowser setAccept={setSubstandardBrowserAccepted} />
      );
    } else if (showUnsupportedSocial && !isSubStandardBrowserAccepted) {
      return (
        <SocialBrowserUnsupported setAccept={setSubstandardBrowserAccepted} />
      );
    }
    else if (!hasMatchingRoute) {
      return <NotFound />;
    } else if (hasNoWebGl) {
      return <WebGLDisabled />;
    }

    return null;
  }, [hasMatchingRoute, hasNoWebGl, isSubStandardBrowserAccepted, showUnsupportedBrowser, showUnsupportedSocial]);

  useEffect(() => {
    setMounted(true);

    if (process.env.IS_DEBUG && router.asPath.match(/^\/make-frontend-error/)) {
      throw new Error('Testing Sentry');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMounted) {
      setRendered(true);

      const { isSupported, needsUpgrade, fromSocial } = isSupportedBrowser();

      if (!isSupported && !fromSocial) {
        setShowUnsupportedBrowser(true);
      }
      else if (needsUpgrade) {
        setHasBanner(true);
      }
      else if (fromSocial) {
        setUnsupportedSocial(true);
      }

      setNoWebGl(!hasWebGl());
    }
  }, [isMounted]);

  useEffect(() => {
    setWindowTooSmall(
      isDesktop() &&
      (windowSize.width < breakpointDesktop ||
        windowSize.height < desktopMinHeight)
    );

    setRotateDevice(
      (isMobile() && windowSize.isLandscape) ||
      (isTablet() && !windowSize.isLandscape)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  useEffect(() => {
    if (errorComponent) {
      setCopy({
        global: sharedCopy.global,
        experience: sharedCopy.experience,
      });
    }

    if (!hasMatchingRoute) {
      setCopy({
        head,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorComponent, hasMatchingRoute, head, sharedCopy]);

  const handleBannerTextClick = () => {
    setShowUnsupportedBrowser(true);
  }

  if (isBypassed) return <>{children}</>;

  const hasError = errorComponent !== null || isWindowTooSmall || shouldRotateDevice;
  const showBanner = !isSubStandardBrowserAccepted && hasBanner;

  return (
    <>
      {!isRendered && <JavaScriptDisabled sharedCopy={sharedCopy} />}
      {isRendered && (
        <AnimatePresence exitBeforeEnter>{errorComponent || children}</AnimatePresence>
      )}
      {isRendered && showBanner && <UnsupportedBanner onTextClick={handleBannerTextClick} />}
      {isRendered && hasError && (
        <Styled.HeaderWrapper>
          <Header hasMenu={false} hasSpotifyCTA={false} />
        </Styled.HeaderWrapper>
      )}
      {isRendered && <AnimatePresence>{isWindowTooSmall ? <WindowTooSmall /> : null}</AnimatePresence>}
      {isRendered && <AnimatePresence>{shouldRotateDevice ? <RotateDevice /> : null}</AnimatePresence>}
    </>
  );
};

export default React.memo(NonFunctionals);

import styled from 'styled-components';

import { ReactComponent as ChevronIcon } from 'svgs/chevron.svg';
import { colors, ColorNames, layout } from 'utils/styles/theme';
import { setTypography, setVh, setVisibility } from 'utils/styles/mixins';

export const Wrapper = styled.div`
  position: fixed;
  height: ${setVh(100)};
  width: 100%;
  background: ${colors.black};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: calc(
      ${setVh(layout.headerFooterHeight.mobile)} +
        ${layout.headerFooterPadding.mobile}rem
    )
    ${layout.headerFooterPadding.mobile}rem 0;
  transform-origin: 50% 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
`;
export const Overlay = styled.div<{ isVisible: boolean }>`
  position: absolute;
  width: 100%;
  height: ${setVh(100)};
  background: ${colors.black};
  opacity: 0.4;
  z-index: ${layout.zIndex.mobileHeader};
  ${({ isVisible }) => setVisibility(isVisible)}
`;
export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  &:last-of-type {
    margin: 100rem 60rem;
  }
`;
export const PlaylistWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 100rem 60rem;
  overflow: hidden;

  svg {
    width: 45rem;
    margin-right: 0;
  }
  width: 100%;
`;

export const Label = styled.div`
  ${({ theme: { locale } }) => setTypography('menuLabel', locale)}
  width: 90%;
`;

export const DivideLine = styled.div`
  width: 100%;
  opacity: 0.2;
  border-top: 1px solid ${colors.white};
`;

export const SelectorWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const VideoCta = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 100rem 60rem;
  overflow: hidden;

  svg {
    width: 45rem;
    margin-right: 0;
  }
  width: 100%;
`;

export const WorldsCta = styled.div`
  ${({ theme: { locale } }) => setTypography('heading5', locale)}
  color: ${colors.roti};
  display: flex;
  align-items: center;
  margin-top: 80rem;
`;

export const ArrowWrapper = styled.div`
  opacity: 0;
`;

export const BackArrowWrapper = styled.div``;

export const BackArrow = styled(ChevronIcon) <{ color?: ColorNames }>`
  color: ${({ color }) => (color ? colors[color] : 'currentColor')};
  width: 25rem;
  transform: rotateY(180deg);
  margin-right: 35rem;
`;

export const BackArrowRight = styled(ChevronIcon) <{ color?: ColorNames }>`
  color: ${({ color }) => (color ? colors[color] : 'currentColor')};
  width: 25rem;
  margin-left: 35rem;
`;

export const Footer = styled.div`
  width: 100vw;
  position: relative;
`;

export const LinkWrapper = styled.div`
  display: flex;
  padding: 0 50rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 40rem;
`;

export const LinkList = styled.a`
  ${({ theme: { locale } }) => setTypography('button', locale)}
  ${({ theme: { locale } }) => locale === 'es' ? 'flex: 1;' : `
    &:not(:last-child) {
      padding-right: 25rem;
    }
  `}

  text-align: center;
`;

export const FooterSpotify = styled.div`
  background: ${colors.blueRibbon};
  height: 90rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 100%;
`;

export const FooterText = styled.p`
  ${({ theme: { locale } }) => setTypography('button', locale)}
  opacity: 0.7;
`;
export const TooltipWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 5;
  width: auto;
  transform: translateY(90%);
  display: flex;
  padding: 0 ${layout.headerFooterPadding.mobile / 2}rem;
  justify-content: flex-start;
`;

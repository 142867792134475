import styled from 'styled-components';

import { hover, setTypography } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';
import { layout } from 'utils/styles/theme';

interface SvgWrapperProps {
  active?: boolean
}

export const MenuWrapper = styled.div`
  position: absolute;
  bottom: 60rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: ${layout.zIndex.mobileHeader};
`;

export const Label = styled.span`
  ${({ theme: { locale } }) => setTypography('subtitle1', locale)}
  margin-right: 16rem;

  ${({ theme: { locale } }) => mediaTablet(`
    ${setTypography('link1', locale)}
  `)}
`;

export const SvgWrapper = styled.div`
  display: inline-block;

  svg {
    width: 30rem;
    transition: transform 0.2s ease-in-out;

    ${mediaTablet(`
      width: 16rem;
    `)}

    ${(props: SvgWrapperProps) => !props.active ?
    `
        transform: translateY(-12.5%) rotate(180deg);
      ` : ''
  }
  }
`;

export const Selector = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20rem;
  margin-top: -20rem;
`;

export const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ${hover(`
    ${Label},
    ${SvgWrapper} {
      opacity: ${layout.hoverOpacity};
    }
  `)}
`;
